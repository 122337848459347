/** @format */
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    classDetail: {},
  },
  mutations: {
    setEditData(state, data) {
      state.classDetail = data
    },
  },
  actions: {
    fetchListData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/pro-cate/list', { params: queryParams })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    editInvoices(ctx, queryParams) {
      console.log(queryParams)
      return new Promise((resolve, reject) => {
        axiosIns
          .put('/pro-cate/edit', queryParams)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    createInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/pro-cate/create', queryParams)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    deleteInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete('/pro-cate/delete', { params: queryParams })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
