/** @format */

import { computed, nextTick, onMounted, reactive, ref, watch } from '@vue/composition-api/dist/vue-composition-api'
import store from '@/store'
import BrandManagerModule from '@/views/GeneralManagement/ProductManager/Brand/BrandManagerModule'
import ClassManagerModule from '@/views/GeneralManagement/ProductManager/Classification/ClassManagerModule'

export default {
  setup() {
    const pprod = ref([])
    const pages = ref(1)
    const total = ref(0)
    const pageSize = ref(100)
    const hasNextPage = computed(() => pages.value * pageSize.value < total.value)
    const hasPrevPage = computed(() => pages.value > 1)
    const productList = reactive([])
    const getProductList = () => {
      // 获取产品列表(品牌列表)
      const INVOICE_APP_STORE_MODULE_NAME = 'brand-module'
      if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, BrandManagerModule)
      store
        .dispatch(`${INVOICE_APP_STORE_MODULE_NAME}/fetchInvoices`, {
          page: pages.value,
        })
        .then(response => {
          const { list, pageSize: limit, total: totalVal } = response.data
          pprod.value = list
          nextTick(() => {
            productList.push(...list)
          })
          total.value = totalVal
          pageSize.value = limit
        })
    }
    // get class about cate
    const pagesClass = ref(1)
    const totalClass = ref(0)
    const pageSizeClass = ref(10)
    const hasNextPageClass = computed(() => pagesClass.value * pageSizeClass.value < totalClass.value)
    const hasPrevPageClass = computed(() => pagesClass.value > 1)
    const classList = reactive([])
    const getClassList = () => {
      // 获取产品列表(品牌列表)
      const INVOICE_APP_STORE_MODULE_NAME = 'class-module'
      if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ClassManagerModule)
      store
        .dispatch(`${INVOICE_APP_STORE_MODULE_NAME}/fetchListData`, {
          page: pages.value,
        })
        .then(response => {
          const { list, pageSize: limit, total: totalVal } = response.data
          nextTick(() => {
            classList.push(...list)
          })
          totalClass.value = totalVal
          pageSizeClass.value = limit
        })
    }

    // onMounted(() => {
    //   Promise.all([getProductList(), getClassList()])
    // })

    watch([pages], () => {
      productList.splice(0, productList.length)
      getProductList()
    })
    return {
      pages,
      total,
      pageSize,
      hasNextPage,
      hasPrevPage,
      productList,
      getProductList,
      pprod,

      pagesClass,
      totalClass,
      pageSizeClass,
      hasNextPageClass,
      hasPrevPageClass,
      classList,
      getClassList,
    }
  },
}
